import { NewCandidate } from "../Classes/NewCandidate";
import axios from "axios";
const url = "/api/candidate";

//If the user is interested it takes him to the apply page..
export const axiosCandidateConnected = async (id) => {
  let info;
  await axios.get(`${url}/${id}`).then((res) => {
    info = res.data;
  });
  return info;
};

export const axiosCreateNewCandidate = async (
  fullName,
  cityId,
  phone,
  campaignId,
  serviceOrderId,
  referrerId,
  referrerDetailId,
  subReferrerDetailId
) => {
  let info;
  let newCandidate = new NewCandidate(
    fullName,
    cityId,
    phone,
    campaignId,
    serviceOrderId,
    referrerId,
    referrerDetailId,
    subReferrerDetailId
  );

  await axios.post(`${url}`, newCandidate).then((res) => {
    info = res.data;
  });
  return info;
};
