import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import "./SentUninterest.css";

const SentUnInterest = () => {
  const paperStyle = {
    height: "auto",
    width: "auto",
    maxWidth: "500px",
    margin: "5% auto",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)",
    borderRadius: "5px",
  };
  return (
    <div className="container sentinterest-container">
      <Paper style={paperStyle}>
        <div className="col headers-container">
          <div className="row justify-content-end header-row">
            <div className="col-8 logo-header">
              <a href="https://www.danel-jobs.co.il">
                <img
                  src={require(`../../Images/Icons/Logo.png`)}
                  alt="Image"
                  loading="lazy"
                  width="85%"
                  className="logo-header-image"
                />
              </a>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
        <div className="col title-block">
          <h1 className="uninterested-first-title">
            לא מתאים? <br /> לא נורא
          </h1>
          <h2 className="uninterested-second-title">
            ננסה למצוא לך משרה
            <br />
            אחרת מתאימה יותר.
          </h2>
        </div>
        <div className="col main-image">
          <img
            src={require(`../../Images/Other/Other2.png`)}
            width="90%"
            height="100%"
          />
        </div>
        <div className="col below-image-text">
          <span className="blue-text">
            אנו ממליצים לך להירשם לסוכן חכם באמצעות הוואטסאפ בוט שלנו ולהתעדכן
            מיד עם פרסום משרות חדשות, העשויות להתאים לך.
          </span>
          <br />
          <br />
        </div>
        <div className="col footer-text container">
          <a href="https://dnljobs.co/WA">
            <div className="blue-text">
              <u>
                הרשמה לסוכן משרות חכם<i className="description-arrow left"></i>
              </u>
            </div>
          </a>
        </div>
      </Paper>
    </div>
  );
};

export default SentUnInterest;
