import config from "../../configuration";

export class General {
  FirstText;
  SecondText;
  ImageIdentifier;

  constructor(imageIdentifier) {
    this.FirstText = "מחפשים עבודה?";
    this.SecondText = "יש לנו משרה בדיוק בשבילך";
    this.setImageIdentifier(imageIdentifier);
  }

  async setImageIdentifier(imageIdentifier) {
    this.ImageIdentifier = await ImageHandler(imageIdentifier);
  }
}

const ImageHandler = async (imageIdentifier) => {
  if (imageIdentifier === config.General1) return "General/General1.png";
  else if (imageIdentifier === config.General2) return "General/General2.png";
  else if (imageIdentifier === config.General3) return "General/General3.png";
  else if (imageIdentifier === config.General4) return "General/General4.png";
  else if (imageIdentifier === config.SmsGeneral)
    return "General/SmsGeneral.png";
  else throw "Wrong image identifier received.";
};
