import React, { useEffect, useState } from "react";
import "./Footer.css";
import {
  axiosCandidateNotInterested,
  axiosCandidateInterested,
} from "../../Services/MessagesService";
import Button from "../Button/Button";
import Cookies from "js-cookie";

const Footer = (props) => {
  const interested = async () => {
    window.location =
      window.location.href.slice(0, window.location.href.indexOf("/", 10)) +
      "/thankyoucandidate";
    const result = await axiosCandidateInterested(
      props.candidateId,
      props.campaignId,
      props.referrerId,
      props.referrerDetailId,
      props.subReferrerDetailId
    );
    if (result) {
      Cookies.set(props.candidateId, props.campaignId, { expires: 7 });
    } else {
      console.log("Error in interested notification!");
    }
  };

  const notInterested = async () => {
    window.location =
      window.location.href.slice(0, window.location.href.indexOf("/", 10)) +
      "/notinterested";
    await axiosCandidateNotInterested(
      props.candidateId,
      props.campaignId,
      props.referrerId,
      props.referrerDetailId,
      props.subReferrerDetailId
    );
  };

  return (
    <div className="container footer-container">
      <div className="row footer-row">
        <div className="col interested-col">
          <Button
            path="InterestedArrowText.png"
            width="100%"
            height="100%"
            onClick={interested}
          />
        </div>
        <div className="col uninterested-col">
          <Button
            path="NotInterestedArrowText.png"
            width="100%"
            height="100%"
            onClick={notInterested}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
