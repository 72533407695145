import "./Main.css";
import React, { useEffect, useState } from "react";
import { Title } from "../../Components/Title/Title";
import { Candidate } from "../../Classes/Candidate";
import { axiosCandidateConnected } from "../../Services/CandidateService";
import { axiosStartupPage } from "../../Services/StartupService";
import { Paper } from "@material-ui/core";
import Header from "../../Components/Header/Header";
import Description from "../../Components/Description/Description";
import Footer from "../../Components/Footer/Footer";
import { createTemplateInstance } from "../../Classes/Templates/Template";
import Skeleton from "react-loading-skeleton";
import Form from "../../Components/Form/Form";
import { axiosProcess } from "../../Services/ProcessService";
import ServiceFooter from "../../Components/ServiceFooter/ServiceFooter";

const Main = (props) => {
  const [serviceOrder, setServiceOrder] = useState("");
  const [template, setTemplate] = useState("");
  const [image, setImage] = useState("");
  const [showFullText, setShowFullText] = useState(false);
  const [view, setView] = useState("");
  const [showContinue, setShowContinue] = useState(false);

  var isServiceOrder = false;
  var identifier;

  if (props.campaignId !== null && props.campaignId !== "default")
    identifier = props.campaignId;
  else {
    identifier = props.serviceOrderId;
    isServiceOrder = true;
  }

  let candidate;
  let candidateInfo;

  const paperStyle = {
    height: "auto",
    width: "auto",
    maxWidth: "500px",
    margin: "5% auto",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)",
    borderRadius: "5px",
  };

  const loadPage = async () => {
    if (props.candidateId != null) {
      candidateInfo = await axiosCandidateConnected(props.candidateId);
      if (candidateInfo && candidateInfo.contactid) {
        candidate = new Candidate(
          candidateInfo.yomifullname,
          candidateInfo.contactid
        );
      }
    }

    let serviceOrderInfo;
    if (isServiceOrder) {
      serviceOrderInfo = await axiosProcess(
        identifier,
        candidateInfo?.contactid,
        props.branchId
      );
    } else {
      serviceOrderInfo = await axiosStartupPage(identifier);
    }

    if ((await validateGuid(identifier)) === false) {
      window.location =
        window.location.href.slice(0, window.location.href.indexOf("/", 10)) +
        "/error";
    }

    if (
      !Object.values(serviceOrderInfo).every((value) => {
        return false;
      })
    ) {
      setServiceOrder(serviceOrderInfo);
      await templateHandler(serviceOrderInfo.templateInformation);
    } else {
      window.location =
        window.location.href.slice(0, window.location.href.indexOf("/", 10)) +
        "/error";
    }

    if (isServiceOrder === false) {
      if (candidate) {
        setView(
          <Footer
            campaignId={identifier}
            candidateId={candidateInfo.contactid}
            referrerId={serviceOrderInfo.referrerId}
            referrerDetailId={serviceOrderInfo.referrerDetailId}
            subReferrerDetailId={serviceOrderInfo.subReferrerDetailId}
          />
        );
      } else {
        setView(
          <Form
            campaignId={identifier}
            referrerId={serviceOrderInfo.referrerId}
            referrerDetailId={serviceOrderInfo.referrerDetailId}
            subReferrerDetailId={serviceOrderInfo.subReferrerDetailId}
          />
        );
      }
    } else {
      if (window.location.href.includes("form")) {
        setView(
          <Form
            serviceOrderId={identifier}
            referrerId={serviceOrderInfo.referrerId}
            referrerDetailId={serviceOrderInfo.referrerDetailId}
            subReferrerDetailId={serviceOrderInfo.subReferrerDetailId}
          />
        );
      } else {
        setView(
          <ServiceFooter
            serviceOrderId={identifier}
            candidateId={candidateInfo.contactid}
            branchPhone={serviceOrderInfo.branchPhone}
            processId={serviceOrderInfo.processId}
          ></ServiceFooter>
        );
      }
    }

    const textDiv = document.getElementsByClassName("job-information")[0];
    if (textDiv.scrollHeight > textDiv.clientHeight) {
      setShowContinue(true);
    } else {
      setShowContinue(false);
    }
  };

  const validateGuid = async (guid) => {
    var pattern =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    if (pattern.test(guid)) return true;
    return false;
  };

  const templateHandler = async (templateInformation) => {
    var template = await createTemplateInstance(templateInformation);
    if (candidate == null && isServiceOrder === false) {
      template.FirstText = "הי, שמענו שיש";
      template.SecondText = "משרה בול בשבילך!";
    }
    setImage(template.ImageIdentifier);
    setTemplate(template);
  };

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <div className="container main-container">
      <Paper style={paperStyle}>
        <div className="col header">
          <Header />
        </div>
        <div className="col title">
          {template ? (
            <Title
              firstTitle={template.FirstText}
              secondTitle={template.SecondText}
            />
          ) : (
            <Skeleton
              count={2}
              height={25}
              direction="rtl"
              width="80%"
              style={{ lineHeight: "30px" }}
            />
          )}
        </div>
        <div className="col main-image">
          {image ? (
            <img
              src={require(`../../Images/${image}`)}
              width="90%"
              height="100%"
              alt=""
            />
          ) : (
            <Skeleton
              count={1}
              height={100}
              width="90%"
              direction="rtl"
              style={{ lineHeight: "100px" }}
            />
          )}
        </div>
        <div
          className={`job-information ${
            showFullText ? "full-job-information" : "job-information"
          }`}
        >
          <Description
            title={serviceOrder.listTitle}
            body={serviceOrder.listParagraphOne}
            demands={serviceOrder.listParagraphTwo}
          />
        </div>
        {showContinue ? (
          <div
            className="col continue-button"
            onClick={() => setShowFullText(true)}
          >
            <div
              className={`show-continue ${
                showFullText ? `dont-show-continue` : "show-continue"
              }`}
            >
              {document.documentElement.dir === "rtl" ? (
                <span>
                  להמשך<i className="description-arrow left"></i>
                </span>
              ) : (
                <span>
                  {" "}
                  <i className="description-arrow left"></i>להמשך
                </span>
              )}
            </div>
          </div>
        ) : null}
        {view ? (
          <div className="col footer">{view}</div>
        ) : (
          <div className="row">
            <div className="col skeleton-load">
              <Skeleton
                count={1}
                height={100}
                width="100%"
                direction="rtl"
                style={{ lineHeight: "100px" }}
              />
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default Main;
