import "./Description.css";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Description = (props) => {
  const divChar = "|^";

  const englishRegex =
    // /^[a-zA-Z]+(([\'\,\.\-_ \/)(:][a-zA-Z_ ])?[a-zA-Z_ .]*)*$/;
    /[\u0590-\u05FF]/;

  function checkLanguage() {
    var body = props.body?.split("•").join("- ");
    var demands = props.demands?.split("•").join("- ");
    body = body?.split("–").join("-");
    demands = demands?.split("–").join("-");

    if (
      (props.title != undefined && englishRegex.test(props.title)) ||
      englishRegex.test(body) ||
      englishRegex.test(demands)
    ) {
      document.documentElement.dir = "rtl";
      document.getElementsByClassName("description-body")[0].style.textAlign =
        "right";
    } else if (props.title != undefined) {
      document.documentElement.dir = "ltr";
      document.getElementsByClassName("description-body")[0].style.textAlign =
        "left";
    }
  }

  function handleText(text) {
    let arrangedBodyText = handleTextDivReplace(text);
    return arrangedBodyText;
  }

  function handleTextDivReplace(text) {
    const bodyLines = text.split(divChar);
    const multiLineBody = bodyLines.join("<br />");
    return multiLineBody;
  }

  function handleDescriptionText() {
    let bodyContainer = document.getElementsByClassName("body-text")[0];
    let demandsContainer = document.getElementsByClassName("demands-text")[0];

    if (bodyContainer != undefined && demandsContainer != undefined) {
      if (props.body.includes(divChar)) {
        let bodyText = handleText(props.body);
        bodyContainer.innerHTML = bodyText;
      } else {
        bodyContainer.innerHTML = props.body;
      }

      if (props.demands.includes(divChar)) {
        let demandsText = handleText(props.demands);
        demandsContainer.innerHTML = demandsText;
      } else {
        demandsContainer.innerHTML = props.demands;
      }
    }
  }

  useEffect(() => {
    handleDescriptionText();
    checkLanguage();
  }, [props.body, props.demands, props.title]);

  return (
    <div className="container description-container">
      <h4 className="description-title">{props.title}</h4>
      <div className="description-body">
        <div>
          {props.body ? (
            document.documentElement.dir == "rtl" ? (
              <div>תיאור התפקיד:</div>
            ) : (
              <div>Job Description:</div>
            )
          ) : (
            <Skeleton
              count={1}
              height={20}
              direction="rtl"
              width="50%"
              style={{ lineHeight: "30px" }}
            />
          )}
          {props.body ? (
            <div className="body-text"></div>
          ) : (
            <Skeleton
              count={2}
              height={20}
              direction="rtl"
              width="80%"
              style={{ lineHeight: "30px" }}
            />
          )}
        </div>
        <div>
          {props.demands ? (
            document.documentElement.dir == "rtl" ? (
              <div className="demands-title">דרישות התפקיד:</div>
            ) : (
              <div>Job Requirements:</div>
            )
          ) : (
            <Skeleton
              count={1}
              height={20}
              direction="rtl"
              width="50%"
              style={{ lineHeight: "30px" }}
            />
          )}
          {props.demands ? (
            <div className="demands-text"></div>
          ) : (
            <Skeleton
              count={2}
              height={20}
              direction="rtl"
              width="80%"
              style={{ lineHeight: "30px" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Description;
