import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import SentInterest from "./Pages/SentInterest/SentInterest";
import SentUninterest from "./Pages/SentUninterest/SentUninterest";
import FriendSentInterest from "./Pages/FriendSentInterest/FriendSentInterest";
import Preview from "./Pages/Preview/Preview";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Routes>
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/" element={<ErrorPage />} />
      <Route path="/:campaignId" element={<App />} />
      <Route path="/:campaignId/disablecookies" element={<App />} />
      <Route
        path="/process/:serviceOrderId/:branchId/:candidateId"
        element={<App />}
      />
      <Route
        path="/process/:serviceOrderId/:branchId/:candidateId/form"
        element={<App />}
      />
      <Route path="/thankyoucandidate" element={<SentInterest />} />
      <Route path="/thankyou" element={<FriendSentInterest />} />
      <Route path="/notinterested" element={<SentUninterest />} />
      <Route path="/interested" element={<SentInterest />} />
      <Route
        path="/preview/:templateImage/:jobTitle/:description/:demands"
        element={<Preview />}
      />
    </Routes>
  </BrowserRouter>,
  rootElement
);
