import "./custom.css";
import React from "react";
import { useParams } from "react-router-dom";
import Main from "./Pages/Main/Main";

function App() {
  const params = useParams();
  let candidateId;
  const campaignId = params.campaignId;
  const serviceOrderId = params.serviceOrderId;
  const branchId = params.branchId;
  var guidPattern =
    /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}|[0-9A-Fa-f]{8}=)/g;

  var guids = window.location.href.match(guidPattern);

  if (guids && guids.length <= 3 && guids.length > 1) {
    candidateId = guids[guids.length - 1].replace("=", "");
  }

  return (
    <div className="full-container">
      <Main
        campaignId={campaignId || null}
        branchId={branchId || null}
        serviceOrderId={serviceOrderId}
        candidateId={candidateId || null}
      />
    </div>
  );
}

export default App;
